import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"

import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import '@trendmicro/react-sidenav/dist/react-sidenav.css'
import LeftMenu from './leftMenu'
const NationalStudentEssayCompetition = () => (
  <Layout>
    <SEO title="National Student Essay Competition" />

    <div className="subpageContainer">

      <LeftMenu active="advisory-committee" />
      <div className="body">


        <h1 style={{marginTop: '55px'}}>when the message is the medium</h1>

        
        <h4>National Student Essay Competition Honorary Advisory Committee</h4>

        <div className="committee-members">
        <div><span className="committee-member">Rolena Adorno</span>, Sterling Professor of Spanish. Yale University</div>
        <div><span className="committee-member">Nick Akerman</span>, Partner, Dorsey Whitney law firm. Former Watergate Prosecutor</div>
        <div><span className="committee-member">Hala Al-Dosari</span>, Inaugural Jamal Khashoggi Fellow, Washington Post. Robert E. Wilhelm Fellow at MIT Center for International Studies</div>
        <div><span className="committee-member">Karen Attiah</span>, Editor and Writer, Global Opinions, Washington Post</div>
        <div><span className="committee-member">Nancy Gibbs</span>, Director, Kennedy School Shorenstein Center on Media, Politics and Public Policy. Edward R. Murrow Chair of Press, Politics, and Public Policy, Harvard Kennedy School. Former editor in chief of TIME</div>
        <div><span className="committee-member">Eddie S. Glaude, Jr.</span>, Chair of the Center for African-American Studies &  William S. Tod Professor of Religion and African-American Studies, Princeton  University</div>
        <div><span className="committee-member">Doris Kearns Goodwin</span>, Pulitzer Prize-winning presidential historian & author</div>
        <div><span className="committee-member">Lee Huebner</span>, Airlie Professor of Media and Public Affairs at George Washington University. Former CEO & Publisher of the Paris-based International Herald-Tribune</div>
        <div><span className="committee-member">Neal Katyal</span>, Paul and Patricia Saunders Professor of National Security Law at Georgetown University Law Center. Former Acting Solicitor General of the United States</div>
        <div><span className="committee-member">Chris Matthews</span>, Author, Journalist, Hosted “Hardball,” Lecturer at Fulbright University. </div>
        <div><span className="committee-member">Barbara McQuade</span>, Professor, University of Michigan Law School. Former U.S. Attorney</div>
        <div><span className="committee-member">Jon Meacham</span>, Pulitzer Prize-winning presidential biographer, editor and columnist</div>
        <div><span className="committee-member">Norman J. Ornstein</span>, American political scientist and resident scholar, American Enterprise Institute (AEI)</div>
        <div><span className="committee-member">Courtney Radsch</span>, Director of Advocacy, Committee to Protect Journalists </div>
        <div><span className="committee-member">Jason Rezaian</span>, Global Opinions writer for the Washington Post. Formerly the Washington Post correspondent in Tehran</div>
        <div><span className="committee-member">Jenn Topper</span>, Communications Director, Reporters Committee for Freedom of the Press</div>
        <div><span className="committee-member">Laurence Tribe</span>, Carl M. Loeb University Professor and Professor of Constitutional Law at Harvard Law School</div>
        <div><span className="committee-member">Joyce White Vance</span>, Distinguished Professor of the Practice of Law, University of Alabama. Former U.S. Attorney</div>
        <div><span className="committee-member">Jill Wine-Banks</span>, Attorney. Former Watergate Prosecutor.</div>
        </div>
    </div>

    </div>
    
    {/* <SideNav
    style={{top: '140px', zIndex: 0, background: "rgba(255,219,0,1)"}}
    onSelect={(selected) => {
        // Add your code here
    }}
>
    <SideNav.Toggle />
    <SideNav.Nav defaultSelected="home">
        <NavItem eventKey="home">
            <NavIcon>
                <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText>
                Home
            </NavText>
        </NavItem>
        <NavItem eventKey="partners">
            <NavIcon>
                <i className="fa fa-fw fa-handshake" style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText>
                Partners
            </NavText>
            <NavItem eventKey="charts/linechart">
                <NavText>
                    Line Chart
                </NavText>
            </NavItem>
            <NavItem eventKey="charts/barchart">
                <NavText>
                    Bar Chart
                </NavText>
            </NavItem>
            <NavItem eventKey="charts/barchart">
                <NavText>
                    Partners
                </NavText>
            </NavItem>
        </NavItem>
        <NavItem eventKey="charts">
            <NavIcon>
                <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText>
                Charts
            </NavText>
            <NavItem eventKey="charts/linechart">
                <NavText>
                    Line Chart
                </NavText>
            </NavItem>
            <NavItem eventKey="charts/barchart">
                <NavText>
                    Bar Chart
                </NavText>
            </NavItem>
            <NavItem eventKey="charts/barchart">
                <NavText>
                    Partners
                </NavText>
            </NavItem>
        </NavItem>
    </SideNav.Nav>
</SideNav> */}



  </Layout>
)

export default NationalStudentEssayCompetition
